import { defineStore } from 'pinia';
import CartApi from '@/sheep/api/trade/cart';
const cart = defineStore({
  id: 'cart',
  state: () => ({
    list: [], // 购物车列表
    selectedIds: [], // 已选列表
    isAllSelected: false, // 是否全选
    totalPriceSelected: 0, // 选中项总金额
  }),
  actions: {
    // 获取购物车列表
    async getList() {
      const { data, code } = await CartApi.getCartList();
      if (code === 0) {
        this.list = data.validList;

        // 计算各种关联属性
        this.selectedIds = [];
        this.isAllSelected = true;
        this.totalPriceSelected = 0;
        this.list.forEach((item) => {
          if (item.selected) {
            if (item.spuItems) this.selectedIds.push(item.spu.id)
            else this.selectedIds.push(item.id);
            if (item.sku == null) return
            this.totalPriceSelected += item.count * item.sku.price;
          } else {
            this.isAllSelected = false;
          }
        });
      }
    },

    // 添加购物车
    async add(goodsInfo, data) {
      // 添加购物项
      const { code } = await CartApi.addCart({
        skuId: goodsInfo.id,
        count: goodsInfo.goods_num,
        itemExtras: data,
        addressId: data.addressId,
        pickUpStoreId: data.pickUpStoreId,
        pickUpStoreName: data.pickUpStoreName,
        pickUpStorePhone: data.pickUpStorePhone,
        pickUpStoreAddress: data.pickUpStoreAddress,
        deliveryType: data.deliveryType,
        shipDate: data.shipDate,
        ship_date: data.ship_date,
        ship_time_text: data.ship_time_text,
        shop_name: data.shop_name,
        shop_id: data.shop_id,
        lat: data.lat,
        lng: data.lng,
        supplierDeliveryPrice: data.supplierDeliveryPrice
      });
      // 刷新购物车列表
      if (code === 0) {
        await this.getList();
      }
    },

    async addCombineGoods(goodsInfo, childs, count) {
      // 添加购物项
      const { code } = await CartApi.addCombineCart({
        spuId: goodsInfo.id,
        count: count,
        items: childs,
      })
      if (code === 0) {
        await this.getList();
      }
    },

    // 更新购物车
    async update(goodsInfo) {
      const { code } = await CartApi.updateCartCount({
        id: goodsInfo.goods_id,
        count: goodsInfo.goods_num,
      });
      if (code === 0) {
        await this.getList();
      }
    },

    // 更新购物车组合商品
    async updateBundle(goodsInfo) {
      const { code } = await CartApi.updateCartCountBundle({
        spuId: goodsInfo.goods_id,
        count: goodsInfo.goods_num,
      })
      if (code === 0) {
        await this.getList();
      }
    },

    // 移除购物车
    async delete(ids) {
      const { code } = await CartApi.deleteCart(ids.join(','));
      if (code === 0) {
        await this.getList();
      }
    },

    // 移除购物车组合商品
    async deleteBundle(spuIds) {
      const { code } = await CartApi.deleteCartBundle(spuIds.join(','), null);
      if (code === 0) {
        await this.getList();
      }
    },

    // 单选购物车商品
    async selectSingle(goodsId) {
      const { code } = await CartApi.updateCartSelected({
        ids: [goodsId],
        selected: !this.selectedIds.includes(goodsId), // 取反
      });
      if (code === 0) {
        await this.getList();
      }
    },

    // 单选购物车组合商品
    async selectSingleBundle(spuId) {
      const activityId = uni.getStorageSync("activityId") || "";
      const { code } = await CartApi.updateCartSelectedBundle({
        activityId: activityId,
        spuIds: [spuId],
        selected: !this.selectedIds.includes(spuId), // 取反
      });
      if (code === 0) {
        await this.getList();
      }
    },

    // 全选购物车商品
    async selectAll(flag) {
      var commonIds = [];
      var bundleIds = [];

      this.list.map((item) => {
        if (item.spuItems) bundleIds.push(item.spu.id)
        else commonIds.push(item.id);
      })

      if (commonIds.length > 0) {
        const { code } = await CartApi.updateCartSelected({
          ids: commonIds,
          selected: flag,
        });

      }
      if (bundleIds.length > 0) {
        const res = await CartApi.updateCartSelectedBundle({
          activityId: activityId,
          spuIds: bundleIds,
          selected: flag,
        })
      }
      if (bundleIds.length > 0 || commonIds.length > 0) {
        await this.getList();
      }

    },

    // 清空购物车。注意，仅用于用户退出时，重置数据
    emptyList() {
      this.list = [];
      this.selectedIds = [];
      this.isAllSelected = false;
      this.totalPriceSelected = 0;
    },

    // 分解组合商品和普通商品的ID
    splitIds() {
      const spuIds = [];
      const skuIds = [];
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'cart-store',
      },
    ],
  },
});

export default cart;
